#container {
  width: 60%;
  margin: auto;
}

input {
  text-align: center;
  justify-content: center;
}

#margin {
  width: 50px;
}

#searchBTN {
  color: white;
  background: linear-gradient(45deg, #3f5efb, #fc466b);
  border: 0px solid;
  background-color: white;
}

input {
  background: white;
}

form {
  margin-bottom: 0px;
  margin-top: 35px;
  border: 3px solid;
  border-radius: 8px;
  border-color: white;
  background-color: white;
  background-clip: border;
}

table {
  margin: auto;
  text-align: center;
  justify-content: center;
  border: 3px solid;
  border-radius: 0px;
  border-color: white;
}

.success {
  color: green;
}

tr {
  height: 50px;
}

thead {
  background: linear-gradient(90deg, #3f5efb, #fc466b);
  border: 0px solid;
  border-color: white;
  margin: auto;
}

th {
  color: white;
}

tbody {
  margin: auto;
  background: repeating-linear-gradient(
    0deg,
    #e4e4e4 0 50px,
    #ffffff 50px 100px
  );
}

td {
  font-weight: bold;
}

#noResults {
  text-align: center;
  color: white;
  font-size: 30px;
}
