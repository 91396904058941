#animationPageText {
  color: black;
}

#controlsTitle {
  color: black;
}

#controls {
  margin-bottom: 15px;
  margin-left: 25px;
}
