#canvasHead {
  color: black;
}

canvas {
  width: 100%;
}

#controlBlock {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  float: center;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  background: linear-gradient(180deg, #e9e9e9, #a7a7a7, #474747);
}

#zoomControls {
  display: flex;
  margin: auto;
}

#timeControls {
  display: flex;
  margin: auto;
}

ul {
  margin-top: 0px;
  margin-bottom: 5px;
  margin-right: 3%;
  margin-left: 3%;
  padding: 0px;
  width: 40%;
  text-align: center;
}

#zoomText {
  margin-bottom: 0px;
  color: black;
  font-weight: bold;
  font-size: 1.6vh;
}

#timeText {
  margin-bottom: 0px;
  color: black;
  font-weight: bold;
  font-size: 1.6vh;
}

#CANVAS {
  height: 100%;
}

#infoSec {
  background-color: white;
}

#prop {
  color: white;
}
